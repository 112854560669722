import React from 'react';
import { createRoot } from 'react-dom/client';
import AppShell from './AppShell';
import store from './redux/store/store.js';
import { Provider } from 'react-redux';
import Toast from './components/utilities/Toast';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import AppTheme from './theme';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <ThemeProvider theme={AppTheme}>
        <AppShell />
        <Toast />
      </ThemeProvider>
    </Provider>
  </StyledEngineProvider>,
);
// import * as serviceWorker from './serviceWorker';

// import { SW_INIT, SW_UPDATE } from "./redux/constants/action-types";
// window.location = "/"

// ReactDOM.render(
//   <React.StrictMode>
//     {/* <ScriptsLoader /> */}
//     <Provider store={store}>
//       <AppShell />
//       <Toast />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root"),
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: (registration) => {
//     store.dispatch({ type: SW_UPDATE, payload: registration })
//   },
// });
