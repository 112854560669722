/**
 * Contains all possible types for actions. This is also provides a single source of truth for all possible actions
 */

// Maps Action names to the variables that they change for the root reducer
export const ALL_TYPES = {
  APPLICATION_LOADING_STATE: 'appLoading', // App Loading State
  GRID_LOADING_STATE: 'gridLoadingState',
  ALL_GROUPS: 'allGroups',
  SET_LOGIN_STATE: 'loginState', // true if user is logged in
  SET_APP_TYPE: 'appType', // Sets the App Type
  SET_USER_TYPE: 'userType', // Type of user  (state, county)
  SET_USER_SETTINGS: 'userSettings', // true if user is logged in
  SET_PALMETTO_SELECTED_GROUP: 'selectedPalmettoGroup', // User Selected Group ID
  SET_PALMETTO_SELECTED_GROUP_NAME: 'selectedPalmettoGroupName', // User Selected Group Name
  SET_HEALTH: 'health', // true if the connection is still active and healthy
  PALMETTO_USER_ACCOUNT: 'palmettoUserAccount', // basic user information from palmetto
  ALL_COUNTIES: 'allCounties',
  CURRENT_COUNTY: 'currentCounty',
  CURRENT_IMG: 'currentImg',
  SET_YEAR: 'year',
  SET_SELECTED_YEAR_SETTING_ID: 'selectedYearSettingID',
};
