// noinspection JSUnusedLocalSymbols

import React, { Suspense } from 'react';
import AWS from 'aws-sdk';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from './components/utilities/History';

import { Loading } from '@zawarski/palmetto-ui-components';
// Components
import { ApplicationSpinner } from '@zawarski/palmetto-ui-components';

// Import CSS Styles
import '@zawarski/palmetto-ui-components/dist/styles/common.css';
import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout.css';
import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout-classes.css';

const HomeComponent = React.lazy(() => import('./components/app/Home'));
const StateCountiesComponent = React.lazy(() => import('./components/app/StateCountiesOverall'));
const EditPageComponent = React.lazy(() => import('./components/app/EditPage'));
const ViewComponent = React.lazy(() => import('./components/app/ViewPage'));
// const AddEntryComponent = React.lazy(() => import('./components/app/AddEntry'));

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Generated on step 1
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Generated on step 1
  region: 'us-east-1', // Must be the same as your bucket
  signatureVersion: 'v4',
});

function App() {
  const appLoading = useSelector((state) => state.rootReducer.appLoading);
  const userType = useSelector((state) => state.rootReducer.userType);
  // const groupName = useSelector((state) => state.rootReducer.selectedPalmettoGroupName);

  return (
    <>
      {appLoading ? (
        <div className='layout vertical vertical-center full-height'>
          <ApplicationSpinner />
          <div className='height-20'></div>
          <span style={{ padding: '0 16px' }} className='text-center title-big opacity-54'>
            Loading the app
          </span>
        </div>
      ) : (
        <div className='full-height'>
          <Router history={history}>
            {/* id can be extracted from props.match.params.id */}
            <Switch>
              <Route
                exact
                path='/'
                component={(props) => (
                  <Suspense fallback={<Loading />}>
                    <HomeComponent {...props} />
                  </Suspense>
                )}
              />
              <Route
                exact
                path='/counties'
                component={(props) => (
                  <Suspense fallback={<Loading />}>
                    <StateCountiesComponent {...props} />
                  </Suspense>
                )}
              />
              <Route
                exact
                path='/county/:id'
                component={(props) => (
                  <Suspense fallback={<Loading />}>
                    <ViewComponent {...props} userType={userType} />
                  </Suspense>
                )}
              />
              <Route
                exact
                path='/county/:id/edit'
                component={(props) => (
                  <Suspense fallback={<Loading />}>
                    <EditPageComponent {...props} />
                  </Suspense>
                )}
              />
            </Switch>
          </Router>
        </div>
      )}
    </>
  );
}

export default App;
