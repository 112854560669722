/**
 * Reducers produce the state of the application.
 * Reducers know when to change state is by sending a signal to the store.
 * The signal is an action. "Dispatching an action" is the process of sending out a signal.
 *
 * Important thing to note is you dont change an existing state. You make a copy of that state with current plus new data.
 */

import { combineReducers } from 'redux';

import { ALL_TYPES } from '../constants/action-types.js'; // All action types are here

const initialState = {
  appLoading: true,
  loginState: false,
  saving: false,
  appType: null,
  userSettings: null,
  selectedGroup: null,
  selectedGroupName: '',
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
  palmettoUserAccount: {},
  allCounties: [],
  currentCounty: {},
  currentImg: {},
  year: '2020',
  selectedYearSettingID: null,
  //     {
  //         county_name: 'Abbevillie',
  //         updated: '09/08/19',
  //         additional_information: 'Test test test',
  //     },
  //     {
  //         county_name: 'Aiken',
  //         updated: '09/08/19',
  //         additional_information: 'Test test test',
  //     },
  //     {
  //         county_name: 'Allendale',
  //         updated: '09/08/19',
  //         additional_information: 'Test test test',
  //     },
  //     {
  //         county_name: 'Anderson',
  //         updated: '09/08/19',
  //         additional_information: 'Test test test',
  //     },
  //     {
  //         county_name: 'Bamberg',
  //         updated: '09/08/19',
  //         additional_information: 'Test test test',
  //     }
  // ]
};

// Handles Setting the state outright
function rootReducer(state = initialState, action) {
  const newState = {};
  if (ALL_TYPES[action.type]) {
    newState[ALL_TYPES[action.type]] = action.payload;
    return Object.assign({}, state, newState);
  }

  // if (action.type === SW_INIT) {
  //     newState.serviceWorkerInitialized = !state.serviceWorkerInitialized;
  //     return Object.assign({}, state, newState);
  // }

  // if (action.type === SW_UPDATE) {
  //     state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
  //     state.serviceWorkerRegistration = action.payload;
  //     return JSON.parse(JSON.stringify(state));
  // }
  return state; // Always return the old state in the default case
}

// If needed, and if the rootReducer can't do certain actions, we can add more reducers here
export default combineReducers({
  rootReducer,
});
