import { apiClient } from '../../common/axios_instance';

/**
 * This file contains most of the actions used around the application
 */

/**
 * This sets the default info right after the user logs in
 * @param {Object} payload
 */

export function setDefaultInfo(payload) {
  return async function (dispatch, getState) {
    console.log(payload);
    dispatch({ type: 'SET_USER_TYPE', payload: payload.userType });
    dispatch({
      type: 'SET_PALMETTO_SELECTED_GROUP_NAME',
      payload: payload.selectedCounty.pvGroupName,
    });

    let year = await getSelectedYearSetting(payload, dispatch, getState);

    if (payload.userType === 'COUNTY_USER') {
      try {
        await apiClient.get(
          process.env.REACT_APP_SERC_SYSTEM_API_ENDPOINT +
            '/api/serccounties/get/' +
            payload.selectedCounty.pvGroupName +
            '/' +
            year,
        );
      } catch (e) {
        return;
        // throw error page
      }
    }

    dispatch({ type: 'SET_PALMETTO_SELECTED_GROUP', payload: payload.selectedCounty });
    dispatch({ type: 'PALMETTO_USER_ACCOUNT', payload: payload.palmettoUserAccount });

    dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
  };
}

async function getSelectedYearSetting(payload, dispatch, getState) {
  let f = {
    where: {
      and: [
        {
          pvAccountID: payload.palmettoUserAccount.id,
        },
        {
          pvGroupID: payload.selectedCounty.pvGroupID,
        },
        {
          pvSettingType: 'sercSelectedYear',
        },
      ],
    },
  };

  let settingsRes = await apiClient.get(
    process.env.REACT_APP_PALMETTO_ENDPOINT + '/api/settings?filter=' + JSON.stringify(f),
  );

  if (settingsRes && settingsRes.data && settingsRes.data.length) {
    dispatch({
      type: 'SET_SELECTED_YEAR_SETTING_ID',
      payload: settingsRes.data[0].pvSettingID || null,
    });

    dispatch({ type: 'SET_YEAR', payload: settingsRes.data[0].pvSettingValue || null });
    return settingsRes.data[0].pvSettingValue;
  }

  return '2020';
}

export function setSelectedYearSetting(payload, callback) {
  return async function (dispatch, getState) {
    const { selectedYearSettingID, selectedPalmettoGroup, palmettoUserAccount } =
      getState().rootReducer;

    try {
      let body = {
        pvSettingID: selectedYearSettingID,
        pvSettingType: 'sercSelectedYear',
        pvSettingValue: payload.year,
        pvGroupID: selectedPalmettoGroup.pvGroupID,
        pvAccountID: palmettoUserAccount.id,
      };

      await apiClient.put(process.env.REACT_APP_PALMETTO_ENDPOINT + '/api/settings', body, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      window.location.reload();
      // callback && callback()
    } catch (e) {
      console.error('error occured while changing groups', e);
    }
  };
}
